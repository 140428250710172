<template>
  <div class="feed-filter">
    <div class="feed-filter__label">
      Mapping for
    </div>
    <FeedSelector
      :feeds="feedOptions"
      :selected-feed="selectedFeed"
      @feed-selected="selectFeed"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import FeedSelector from './FeedSelector';

export default {
  components: {
    FeedSelector,
  },
  setup() {
    const store = useStore();

    const feedOptions = computed(() => ['huddle', ...store.getters.mappingPrimaryFeeds, ...store.getters.mappingSecondaryFeeds]);
    const selectedFeed = computed(() => store.getters.mappingSelectedFeed);

    const selectFeed = (feed) => {
      if (feed === selectedFeed.value) return;
      store.dispatch('clearMappingTable');
      store.dispatch('selectMappingFeed', feed);
    };

    return {
      feedOptions,
      selectedFeed,
      selectFeed,
    };
  },
};
</script>

<style lang="scss" scoped>
.feed-filter {
  position: relative;
  display: flex;
  align-items: center;
  height: 33px;

  &:last-child {
    margin-left: 12px;
  }
}

.feed-filter__label {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #A9A9A9;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
