<template>
  <div
    ref="feedFilter"
    class="feeds-order-root"
  >
    <div
      class="selected-filter-feeds feeds-order"
      @click="
        () => {
          feedsOrderDropdownOpened = !feedsOrderDropdownOpened;
        }
      "
    >
      <div class="feeds-order-wrapper">
        Display feeds
      </div>
      <Icon
        :name="'chevron-down'"
        v-if="!feedsOrderDropdownOpened"
      />
      <Icon
        :name="'chevron-up'"
        v-else
      />
    </div>
    <div
      class="filter-dropdown-feeds feed"
      v-if="feedsOrderDropdownOpened"
    >
      <div
        @dragover="onDragOver"
        @drop="onDisplayedFeedsDrop"
        class="dropdown-section"
      >
        <div class="dropdown-section-title">
          <p>Displayed</p>
        </div>
        <div class="dropdown-option feed disabled">
          <Icon
            :name="'burger-menu'"
          />
          <span>Huddle</span>
        </div>
        <div
          :class="[
            'dropdown-option feed',
          ]"
          v-for="(option, index) in displayedFeeds"
          :key="index"
          draggable="true"
          @dragstart="onFeedDrag($event, option)"
          @dragover="onDragOver"
          @drop="onDisplayedFeedDrop($event, option)"
        >
          <Icon
            :name="'burger-menu'"
          />
          {{ option }}
        </div>
      </div>
      <div
        class="dropdown-section"
        @dragover="onDragOver"
        @drop="onAvailableFeedsDrop"
      >
        <div class="dropdown-section-title">
          <p>Available</p>
        </div>
        <div
          :class="[
            'dropdown-option feed',
          ]"
          v-for="(option, index) in availableFeeds"
          :key="index + 1"
          draggable="true"
          @dragstart="onFeedDrag($event, option)"
        >
          <Icon
            :name="'burger-menu'"
          />
          {{ option }}
        </div>
        <div
          v-if="!availableFeeds.length"
          class="no-available-feeds"
        >
          Drop here
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { filter, includes, sortBy } from 'lodash';
import { onClickOutside } from '@vueuse/core';
import { feedsOrder } from '@/services/helpers/mappings-mapper';
import Icon from '@/components/common/Icon';

export default {
  components: { Icon },
  setup() {
    const store = useStore();
    const feedFilter = ref(false);
    const feedsOrderDropdownOpened = ref(false);
    const displayedFeeds = computed(() => store.getters.mappingDisplayedFeeds);
    const availableFeeds = computed(() => sortBy(filter([
      ...store.getters.mappingPrimaryFeeds,
      ...store.getters.mappingSecondaryFeeds,
    ], (feed) => !includes(displayedFeeds.value, feed)), (feed) => feedsOrder[feed]));

    onClickOutside(feedFilter, () => {
      feedsOrderDropdownOpened.value = false;
    });

    const onFeedDrag = (e, feed) => {
      e.dataTransfer.setData('text', feed);
    };
    const onDisplayedFeedDrop = (e, feed) => {
      const droppedOnFeed = feed;
      const draggedFeed = e.dataTransfer.getData('text');
      if (!includes(displayedFeeds.value, draggedFeed)) return;
      e.stopPropagation();
      if (draggedFeed === droppedOnFeed) return;
      store.dispatch('replaceDisplayedFeedsOrder', {
        feed1: draggedFeed,
        feed2: droppedOnFeed,
      });
    };
    const onDisplayedFeedsDrop = (e) => {
      e.stopPropagation();
      const feedToAdd = e.dataTransfer.getData('text');
      if (!includes(displayedFeeds.value, feedToAdd)) {
        store.dispatch('addDisplayedFeed', feedToAdd);
      }
    };
    const onAvailableFeedsDrop = (e) => {
      e.stopPropagation();
      const feedToRemove = e.dataTransfer.getData('text');
      store.dispatch('removeDisplayedFeed', feedToRemove);
    };
    function onDragOver(e) {
      e.preventDefault();
      return false;
    }

    return {
      displayedFeeds,
      availableFeeds,
      feedFilter,
      feedsOrderDropdownOpened,
      onFeedDrag,
      onDisplayedFeedDrop,
      onDisplayedFeedsDrop,
      onDragOver,
      onAvailableFeedsDrop,
    };
  },
};
</script>

<style lang="scss">
.feeds-order-root {
    height: 100%;
    height: 33px;
    position: relative;
    user-select: none;
    .selected-filter-feeds {
      border: 1px solid #DDDDDD;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      height: 100%;
      margin: 0 12px 0 4px;
      justify-content: space-between;
      min-width: 136px;
      text-transform: capitalize;
      cursor: pointer;

      .icon {
        margin-left: 4px;
        height: 14px;
        width: 14px;
        svg {
          stroke: #191414;
        }
      }

    .feeds-order-wrapper {
      display: flex;
      align-items: center;
    }

    }
    .filter-dropdown-feeds {
      position: absolute;
      top: 40px;
      left: 5px;
      width: 90%;
      background-color: #fff;
      border: 1px solid #EBEBEB;
      box-sizing: border-box;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
      z-index: $dropdownMenuZIndex;
      padding: 4px 0;

      .dropdown-section {
        &:nth-child(1) {
          border-bottom: 1px solid $gray400;
        }
        &:nth-child(2) {
          padding-top: 8px;
        }
        .no-available-feeds {
          font-size: 11px;
          padding: 8px 0;
          text-align: center;
          color: $gray800;
        }
        .dropdown-section-title {
          font-size: 10px;
          font-weight: 600;
          text-transform: uppercase;
          padding: 0 8px;
          color: $gray800;
        }
        .dropdown-option {
          height: 33px;
          display: flex;
          align-items: center;
          padding: 8px;
          box-sizing: border-box;
          cursor: pointer;
          color: $black;
          text-transform: capitalize;
          cursor: grab;

          &.disabled {
            color: $gray700;
            cursor: not-allowed;
          }

          &.feed {
            &:hover {
              background-color: rgba(0, 0, 0, 0.03);
            }
            .icon {
              margin-right: 4px;
            }
          }
        }
      }
   }
}
</style>
