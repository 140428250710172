<template>
  <div
    ref="rootElement"
    class="mapping-state-filter"
  >
    <div
      class="mapping-state-filter__head"
      @click="toggleDropdown"
    >
      <span class="mapping-state-filter__label">
        Display: {{ selectedLabel }}
      </span>
      <Icon
        :name="'chevron-down'"
        v-if="!dropdownOpen"
      />
      <Icon
        :name="'chevron-up'"
        v-else
      />
    </div>
    <div
      class="mapping-state-filter__body"
      v-if="dropdownOpen"
    >
      <div
        class="mapping-state-filter__option"
        @click="toggleMappingState('mapped')"
      >
        <div :class="['mapping-state-filter__checkbox', {'checked': isMappingStateToggled('mapped')}]">
          <Icon
            :name="'check'"
            v-if="isMappingStateToggled('mapped')"
          />
        </div>
        Mapped
      </div>
      <div
        class="mapping-state-filter__option"
        @click="toggleMappingState('not-mapped')"
      >
        <div :class="['mapping-state-filter__checkbox', {'checked': isMappingStateToggled('not-mapped')}]">
          <Icon
            :name="'check'"
            v-if="isMappingStateToggled('not-mapped')"
          />
        </div>
        Not mapped
      </div>
    </div>
  </div>
</template>

<script>
import { includes, find } from 'lodash';
import { onClickOutside } from '@vueuse/core';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  setup() {
    const store = useStore();

    const rootElement = ref(null);
    const dropdownOpen = ref(false);
    const selectedDisplay = computed(() => store.getters.mappingSelectedDisplay);
    const selectedLabel = computed(() => {
      if (!selectedDisplay.value.length) return 'None';
      if (selectedDisplay.value.length === 2) return 'All states';
      const displayOption = find(store.getters.mappingDisplayOptions, { id: selectedDisplay.value[0] });
      return displayOption.label ?? selectedDisplay.value[0];
    });

    const isMappingStateToggled = (displayState) => includes(selectedDisplay.value, displayState);

    const toggleDropdown = () => {
      dropdownOpen.value = !dropdownOpen.value;
    };
    const toggleMappingState = (displayState) => {
      store.dispatch('toggleMappingDisplayState', displayState);
    };

    onClickOutside(rootElement, () => {
      dropdownOpen.value = false;
    });

    return {
      rootElement,
      dropdownOpen,
      selectedLabel,
      isMappingStateToggled,
      toggleDropdown,
      toggleMappingState,
    };
  },
};
</script>

<style lang="scss" scoped>
.mapping-state-filter {
  display: flex;
  align-items: center;
  position: relative;
  width: 160px;
  height: 33px;
  user-select: none;

  &:last-child {
    margin-left: 12px;
  }

  .mapping-state-filter__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 8px;
    width: 100%;
    height: 100%;
    cursor: pointer;

    .mapping-state-filter__label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .icon {
      aspect-ratio: 1/1;
      margin-left: 4px;
      stroke: #191414;
    }

    img {
      height: 14px;
      width: 14px;
      margin-right: 4px;
    }
  }

  .mapping-state-filter__body {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    z-index: $dropdownMenuZIndex;
    padding: 4px 0;

    .mapping-state-filter__option {
      height: 33px;
      display: flex;
      align-items: center;
      padding: 8px;
      box-sizing: border-box;
      cursor: pointer;
      color: #6D6D6D;

      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }

      .mapping-state-filter__checkbox {
        width: 16px;
        height: 16px;
        border: 2px solid #CDCDCD;
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;

        &.checked {
          background-color: #003C3C;
          border: 2px solid #003C3C;
        }

        .icon {
          width: 12px;
          height: 12px;
          stroke: #fff;
        }
      }

      img {
        height: 14px;
        width: 14px;
        margin-right: 4px;
      }

      &.time, &.feed {
        &.selected {
          background-color: rgba(0, 0, 0, 0.02);
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.03);
        }

        .icon {
          margin-right: 4px;
        }
      }

      &.feed {
        padding: 0;
        text-transform: capitalize;
      }
    }
  }
}
</style>
