<template>
  <div
    ref="rootElement"
    class="mapping-time-filter"
  >
    <div
      class="mapping-time-filter__head"
      @click="toggleDropdown"
    >
      <span class="mapping-time-filter__label">
        {{ selectedLabel }}
      </span>
      <Icon
        :name="'chevron-down'"
        v-if="!dropdownOpen"
      />
      <Icon
        :name="'chevron-up'"
        v-else
      />
    </div>
    <div
      class="mapping-time-filter__body"
      v-if="dropdownOpen"
    >
      <div
        v-for="(option, index) in options"
        :key="index"
        :class="['mapping-time-filter__option time', { 'selected': selectedOption === option.value }]"
        @click="selectOption(option.value)"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script>
import { find } from 'lodash';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { onClickOutside } from '@vueuse/core';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  setup() {
    const store = useStore();

    const rootElement = ref(null);
    const dropdownOpen = ref(false);
    const options = computed(() => store.getters.mappingDateOptions);
    const selectedOption = computed(() => store.getters.mappingSelectedDate);
    const selectedLabel = computed(() => {
      const option = find(store.getters.mappingDateOptions, { value: selectedOption.value });
      return option?.label ?? 'N/A';
    });

    const toggleDropdown = () => {
      dropdownOpen.value = !dropdownOpen.value;
    };
    const selectOption = (option) => {
      store.dispatch('selectMappingDate', option);
      dropdownOpen.value = false;
    };

    onClickOutside(rootElement, () => {
      dropdownOpen.value = false;
    });

    return {
      rootElement,
      dropdownOpen,
      options,
      selectedOption,
      selectedLabel,
      toggleDropdown,
      selectOption,
    };
  },
};
</script>

<style lang="scss" scoped>
.mapping-time-filter {
  height: 33px;
  position: relative;
  display: flex;
  align-items: center;
  width: 140px;
  user-select: none;

  &:last-child {
    margin-left: 12px;
  }

  .mapping-time-filter__head {
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    cursor: pointer;

    .mapping-time-filter__label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .icon {
      aspect-ratio: 1/1;
      margin-left: 4px;
      stroke: #191414;
    }

    img {
      height: 14px;
      width: 14px;
      margin-right: 4px;
    }
  }

  .mapping-time-filter__body {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    z-index: $dropdownMenuZIndex;
    padding: 4px 0;

    .mapping-time-filter__option {
      height: 33px;
      display: flex;
      align-items: center;
      padding: 8px;
      box-sizing: border-box;
      cursor: pointer;
      color: #6D6D6D;

      .mapping-time-filter__checkbox {
        width: 16px;
        height: 16px;
        border: 2px solid #CDCDCD;
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;

        &.checked {
          background-color: #003C3C;
          border: 2px solid #003C3C;
        }

        .icon {
          width: 12px;
          height: 12px;

          svg {
            stroke: #fff;
          }
        }
      }

      img {
        height: 14px;
        width: 14px;
        margin-right: 4px;
      }

      &.time, &.feed {
        &.selected {
          background-color: rgba(0, 0, 0, 0.02);
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.03);
        }

        .icon {
          margin-right: 4px;
        }
      }

      &.feed {
        padding: 0;
        text-transform: capitalize;
      }
    }
  }
}
</style>
